import { useState, useMemo, useEffect } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { useSelector } from "react-redux";

const containerStyle = {
  width: "100%",
  height: "360px",
};

const LocationData = () => {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);
  return { lat: latitude, lon: longitude };
};

export const LocationComponent = ({ onChangeData }) => {
  const data = LocationData();
  const { activePlace } = useSelector((state) => state.community);

  const latitudeFromDB =
    activePlace?.geometry?.location?.lat || Number(data.lat);
  const longitudeFromDB =
    activePlace?.geometry?.location?.lng || Number(data.lon);

  const [latitude, setLatitude] = useState(latitudeFromDB);
  const [longitude, setLongitude] = useState(longitudeFromDB);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyAoj8YQGoTRsVTjeW1SiQ902-zODg-MUmw",
  });

  const center = useMemo(
    () => ({
      lat: Number(latitudeFromDB),
      lng: Number(longitudeFromDB),
    }),
    [latitudeFromDB, longitudeFromDB]
  );

  const onClickMarker = (loc) => {
    const lat = loc.latLng.lat();
    const lng = loc.latLng.lng();

    setLatitude(lat);
    setLongitude(lng);

    onChangeData({
      lat,
      lng,
    });
  };

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <GoogleMap
      zoom={14}
      center={center}
      mapContainerStyle={containerStyle}
      onClick={onClickMarker}
    >
      <Marker position={{ lat: latitude, lng: longitude }} />
    </GoogleMap>
  );
};
