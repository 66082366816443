import {
  DollarOutlined,
  EditOutlined,
  EnvironmentOutlined,
  HomeOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const { Sider } = Layout;

function getItem(label, key, icon, children, type) {
  return { key, icon, children, label, type };
}

const items = [
  getItem("Бізнес", "/", <DollarOutlined style={{ fontSize: 16 }} />),
  getItem("Громади", "/state", <HomeOutlined style={{ fontSize: 16 }} />),
  // getItem("Налаштування", "/settings", <SettingOutlined />),
  getItem(
    "Як користуватися",
    "/how-to-use",
    <EditOutlined style={{ fontSize: 16 }} />
  ),
];

export const AdminSider = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;

  const navigateTo = (item) => {
    // if (item.key === "/") {
    //   navigate(`/`);
    // } else {
    navigate(`${item.key}`);
    // }
  };

  return (
    <div style={{ display: "flex" }}>
      <Sider
        style={{
          backgroundColor: "white",
          boxShadow: "0px 2px 8px 0px #00000026",
          minHeight: "calc(100vh - 50px)",
          height: "auto",
          zIndex: "10",
        }}
      >
        <Menu
          // selectedKeys={[pathname]}
          defaultSelectedKeys={[pathname]}
          mode="inline"
          items={items}
          onClick={(e) => navigateTo(e)}
        />
      </Sider>
      <Outlet />
    </div>
  );
};
