import {
  DeleteOutlined,
  PlusOutlined,
  SearchOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Avatar, App, Button, Modal, Table, AutoComplete, Input } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  addNewAdminPlace,
  deleteAdmin,
  getAdministrators,
  getAdministratorsActivePlace,
} from "redux/community/communityOperations";

export const AdminsTablePlace = () => {
  const { message } = App.useApp();
  const dispatch = useDispatch();

  const { idBusiness } = useParams();

  const { activePlace, adminsPlace, usersNoAdminsPlace } = useSelector(
    (state) => state.community
  );

  useEffect(() => {
    dispatch(getAdministrators(idBusiness));
    dispatch(getAdministratorsActivePlace(idBusiness));
  }, []);

  console.log("usersNoAdminsPlace:", usersNoAdminsPlace);
  console.log("adminsPlace:", adminsPlace);
  console.log("activePlace:", activePlace);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [idAdminOnDelete, setIdAdminOnDelete] = useState({});
  const [inputData, setInpuData] = useState(null);
  const [prefixUrl, setPrefixUrl] = useState("/assets/img/avatar.png");
  const isAdmins = Boolean(adminsPlace?.length);

  const messageSucces = (value) => {
    value === "new"
      ? message.success("Адміністратор успішно доданий")
      : message.success("Адміністратор успішно видалений");
  };
  const messageError = (value) => {
    value === "new"
      ? message.error("Виникла помилка при додаванні адміністратора")
      : message.error("Виникла помилка при видаленні адміністратора");
  };

  const showModalAddAdmin = () => {
    setIsModalOpen(true);
  };
  const handleOkAddNewPlaceAdmin = () => {
    dispatch(
      addNewAdminPlace(activePlace, inputData, messageSucces, messageError)
    );
    console.log("handleOk:");
    setIsModalOpen(false);
    setInpuData(null);
    setPrefixUrl("/assets/img/avatar.png");
  };

  const handleCancelAddAdmin = () => {
    setIsModalOpen(false);
    setInpuData(null);
    setPrefixUrl("/assets/img/avatar.png");
  };

  const onChangeInput = (v) => {
    setInpuData(v);

    for (let i = 0; i < usersNoAdminsPlace.length; i++) {
      if (v === usersNoAdminsPlace[i]?.email) {
        setPrefixUrl(usersNoAdminsPlace[i]?.avatar || "/assets/img/avatar.png");
        break;
      } else {
        setPrefixUrl("/assets/img/avatar.png");
      }
    }
  };

  const showDeleteAdminModal = (admin) => {
    setIsDeleteModalOpen(true);
    setIdAdminOnDelete(admin);
  };

  const handleOkDeleteAdminModal = () => {
    dispatch(deleteAdmin(idAdminOnDelete.uid, messageSucces, messageError));

    setIsDeleteModalOpen(false);
  };
  const handleCancelAdminDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setIdAdminOnDelete({});
  };

  const columnsTable = [
    {
      title: "Фото",
      key: "photo",
      width: 80,
      render: (_, item) => (
        <Avatar src={item.avatar ? item.avatar : "/assets/img/avatar.png"} />
      ),
    },
    {
      title: "ПІБ",
      key: "name",
      render: (_, item) =>
        `${item.firstName || "-"} ${item.lastName || ""} ${
          item.thirdName || ""
        }`,
    },
    {
      title: "Телефон",
      render: (_, item) => item.phoneNumber || "-",
      key: "phone",
    },
    {
      title: "Пошта",
      dataIndex: "email",
      key: "email",
      render: (_, item) => (
        <div style={{ display: "flex" }}>
          <span>{item.email || "-"}</span>
          <DeleteOutlined
            style={{
              color: "#D72653",
              fontSize: "16px",
              marginLeft: "auto",
              cursor: "pointer",
            }}
            onClick={() =>
              showDeleteAdminModal({
                uid: item.uid,
                name: item.email,
              })
            }
          />
        </div>
      ),
    },
  ];

  const prefix = <Avatar src={prefixUrl} />;

  const options = usersNoAdminsPlace?.map((user) => {
    return {
      key: user.id,
      value: user?.email || "no email",
      img: user?.avatar,
      label: (
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: "12px" }}>
            <Avatar size={45} src={user?.avatar || "/assets/img/avatar.png"} />
          </div>

          <div>
            <div style={{ fontSize: "16px", fontWeight: "500" }}>
              {user?.firstName || "-"} {user?.lastName || ""}{" "}
              {user?.thirdName || ""}
            </div>
            <div style={{ color: "#8C8C8C" }}> {user?.email || "no email"}</div>
          </div>
        </div>
      ),
    };
  });

  if (isAdmins) {
    return (
      <div className="content-conteiner">
        <Table
          className="towns-admin-table no-active"
          pagination={false}
          dataSource={adminsPlace}
          columns={columnsTable}
        />
        <Button
          onClick={showModalAddAdmin}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "100px 20px 0 auto",
            width: "40px",
            height: "40px",
          }}
          shape="circle"
          type="primary"
        >
          <PlusOutlined style={{ fontSize: "18px" }} />
        </Button>
        <Modal
          className="add-admin-modal"
          title="Видалення адміністратора"
          open={isDeleteModalOpen}
          onOk={handleOkDeleteAdminModal}
          onCancel={handleCancelAdminDeleteModal}
          footer={[
            <Button key="not" onClick={handleCancelAdminDeleteModal}>
              Ні
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={handleOkDeleteAdminModal}
            >
              Так
            </Button>,
          ]}
        >
          <WarningOutlined
            style={{ fontSize: "22px", color: "#FFB62F", marginRight: "15px" }}
          />
          <p style={{ fontSize: "16px", fontWeight: "500" }}>
            Ви дійсно хочете видалити{" "}
            <span style={{ color: "#d72653" }}>"{idAdminOnDelete.name}"</span>?
          </p>
        </Modal>
        <Modal
          className="add-admin-modal"
          title="Додати адміністратора"
          open={isModalOpen}
          onOk={handleOkAddNewPlaceAdmin}
          onCancel={handleCancelAddAdmin}
          footer={[
            <Button key="not" onClick={handleCancelAddAdmin}>
              Відмінити
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={handleOkAddNewPlaceAdmin}
            >
              Зберегти
            </Button>,
          ]}
        >
          <AutoComplete
            // open={false}
            value={inputData}
            allowClear={true}
            onChange={onChangeInput}
            className="auto-complete-add-admin"
            options={options}
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            }
          >
            <Input
              prefix={prefix}
              size="large"
              placeholder="Пошук по email"
              suffix={<SearchOutlined />}
            />
          </AutoComplete>
        </Modal>
      </div>
    );
  } else {
    return (
      <div className="no-admins">
        <img src="/assets/img/no-data.svg" alt="Адміністратори відсутні" />
        <p className="no-admins__text">
          Тереторіальна громада ще немає адміністраторів
        </p>

        <Button onClick={showModalAddAdmin} type="primary">
          Додати адміністратора
        </Button>
        <Button
          onClick={showModalAddAdmin}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "100px 20px 0 auto",
            width: "40px",
            height: "40px",
          }}
          shape="circle"
          type="primary"
        >
          <PlusOutlined style={{ fontSize: "18px" }} />
        </Button>
        <Modal
          className="add-admin-modal"
          title="Додати адміністратора"
          open={isModalOpen}
          onOk={handleOkAddNewPlaceAdmin}
          onCancel={handleCancelAddAdmin}
          footer={[
            <Button key="not" onClick={handleCancelAddAdmin}>
              Відмінити
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={handleOkAddNewPlaceAdmin}
            >
              Зберегти
            </Button>,
          ]}
        >
          <AutoComplete
            // open={false}
            value={inputData}
            allowClear={true}
            onChange={onChangeInput}
            className="auto-complete-add-admin"
            options={options}
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            }
          >
            <Input
              prefix={prefix}
              size="large"
              placeholder="Пошук по email"
              suffix={<SearchOutlined />}
            />
          </AutoComplete>
        </Modal>
      </div>
    );
  }
};
