import {
  DeleteOutlined,
  PlusOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Avatar, App, Button, Modal, Table } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteAdmin } from "redux/community/communityOperations";
import './AdminsTable.css'

export const AdminsTable = ({ showModalAddAdmin }) => {
  const { message } = App.useApp();
  const dispatch = useDispatch();
  const { admins } = useSelector((state) => state.community);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [idAdminOnDelete, setIdAdminOnDelete] = useState({});

  const isAdmins = Boolean(admins?.length);

  const messageSucces = () => {
    message.success("Адміністратор успішно видалений");
  };
  const messageError = () => {
    message.error("Виникла помилка при видаленні адміністратора");
  };

  const showDeleteAdminModal = (admin) => {
    setIsModalOpen(true);
    setIdAdminOnDelete(admin);
  };

  const handleOk = () => {
    dispatch(deleteAdmin(idAdminOnDelete.uid, messageSucces, messageError));

    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setIdAdminOnDelete({});
  };

  const columnsTable = [
    {
      title: "Фото",
      key: "photo",
      width: 80,
      render: (_, item) => (
        <Avatar src={item.avatar ? item.avatar : "/assets/img/avatar.png"} />
      ),
    },
    {
      title: "ПІБ",
      key: "name",
      render: (_, item) =>
        `${item.firstName || "-"} ${item.lastName || ""} ${
          item.thirdName || ""
        }`,
    },
    {
      title: "Телефон",
      render: (_, item) => item.phoneNumber || "-",
      key: "phone",
    },
    {
      title: "Пошта",
      dataIndex: "email",
      key: "email",
      render: (_, item) => (
        <div style={{ display: "flex" }}>
          <span>{item.email || "-"}</span>
          <DeleteOutlined
            style={{
              color: "#D72653",
              fontSize: "16px",
              marginLeft: "auto",
              cursor: "pointer",
            }}
            onClick={() =>
              showDeleteAdminModal({
                uid: item.uid,
                name: item.email,
              })
            }
          />
        </div>
      ),
    },
  ];

  if (isAdmins) {
    return (
      <div className="content-conteiner">
        <Table
          className="towns-admin-table no-active"
          pagination={false}
          dataSource={admins}
          columns={columnsTable}
        />

        <Button
          onClick={showModalAddAdmin}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "100px 20px 0 auto",
            width: "40px",
            height: "40px",
          }}
          shape="circle"
          type="primary"
        >
          <PlusOutlined style={{ fontSize: "18px" }} />
        </Button>

        <Modal
          className="add-admin-modal"
          title="Видалення адміністратора"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="not" onClick={handleCancel}>
              Ні
            </Button>,
            <Button key="submit" type="primary" onClick={handleOk}>
              Так
            </Button>,
          ]}
        >
          <WarningOutlined
            style={{ fontSize: "22px", color: "#FFB62F", marginRight: "15px" }}
          />
          <p style={{ fontSize: "16px", fontWeight: "500" }}>
            Ви дійсно хочете видалити{" "}
            <span style={{ color: "#d72653" }}>"{idAdminOnDelete.name}"</span>?
          </p>
        </Modal>
      </div>
    );
  } else {
    return (
      <div className="no-admins">
        <img src="/assets/img/no-data.svg" alt="Адміністратори відсутні" />
        <p className="no-admins__text">
          Тереторіальна громада ще немає адміністраторів
        </p>

        <Button onClick={showModalAddAdmin} type="primary">
          Додати адміністратора
        </Button>
        <Button
          onClick={showModalAddAdmin}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "100px 20px 0 auto",
            width: "40px",
            height: "40px",
          }}
          shape="circle"
          type="primary"
        >
          <PlusOutlined style={{ fontSize: "18px" }} />
        </Button>
      </div>
    );
  }
};
