export const handleBusiness = (state, { payload }) => {
  state.business = payload;
};

export const handleActiveBusiness = (state, { payload }) => {
  state.activeBusiness = payload;
};

export const handleBusinessAdmins = (state, { payload }) => {
  state.businessAdmins = payload;
};